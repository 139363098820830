<template>
    <div>
        <page-header :title="$t('notes').toUpper()"
                     @new-button-click="createFormShow()"
                     @filter-div-status="datatable.filterStatus=$event"
                     :isNewButton="checkPermission('studentnote_store')"
                     :isColumns="true">
            <template v-slot:columns>
                <div class="mb-1" v-for="(column,key) in datatable.columns">
                    <b-form-checkbox
                        :id="'checkbox-'+key"
                        :name="'checkbox-'+key"
                        :value="false"
                        :unchecked-value="true"
                        v-model="column.hidden"
                        v-if="column.field!='buttons'"
                    >
                        {{ column.label }}
                    </b-form-checkbox>
                </div>
            </template>
        </page-header>
        <datatable-filter v-show="datatable.filterStatus"
                          :filterButtonClass="'mt-1'"
                          @filter="filter"
                          @filterClear="filterClear">
            <b-row>
                <b-col cols="12" md="6">
                    <b-form-group label="Note Content Search">
                        <b-form-input type="text" v-model="datatable.queryParams.filter.note"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group label="User Filter">
                        <staff-auto-complete v-model="datatable.queryParams.filter.created_by"/>
                    </b-form-group>
                </b-col>
            </b-row>
        </datatable-filter>
        <datatable :isLoading.sync="datatable.isLoading"
                   :columns="datatable.columns"
                   :rows="datatable.rows"
                   :total="datatable.total"
                   :queryParams="datatable.queryParams"
                   @on-page-change="onPageChange"
                   @on-sort-change="onSortChange"
                   @on-per-page-change="onPerPageChange"
                   v-show="datatable.showTable"
        />
        <CommonModal ref="formModal" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="clearFormData">
            <template v-slot:CommonModalTitle>
                {{ $t('note').toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <create-form
                             :student="student"
                             @createFormSuccess="createFormSuccess()"
                             v-if="formProcess=='create' && checkPermission('studentnote_store')"
                />
                <update-form
                             :formId="formId"
                             :student="student"
                             @updateFormSuccess="updateFormSuccess()"
                             v-if="formProcess=='update' && checkPermission('studentnote_update')"
                />
                <show-form :studentProgram="student"
                           :formId="formId"
                           v-if="formProcess=='show' && checkPermission('studentnote_show')"
                />
            </template>
        </CommonModal>
    </div>
</template>
<script>
    // Component
    import CommonModal from '@/components/elements/CommonModal.vue';
    import Datatable from '@/components/datatable/Datatable.vue'
    import DatatableFilter from '@/components/datatable/DatatableFilter.vue';
    import PageHeader from '@/components/page/PageHeader.vue'
    import StaffAutoComplete from '@/components/interactive-fields/StaffAutoComplete.vue';

    // Page
    import CreateForm from './CreateForm.vue';
    import UpdateForm from './UpdateForm.vue'

    // Services
    import StudentNoteService from '@/services/StudentNoteService';
    import moment from "moment";

    // Other
    import qs from 'qs';
    import ShowForm from "@/modules/students/pages/student/note/ShowForm.vue";

    export default {
        components: {
            ShowForm,
            CommonModal,
            Datatable,
            DatatableFilter,
            PageHeader,
            StaffAutoComplete,
            CreateForm,
            UpdateForm
        },
        props:{
          student:{}
        },
        data() {
            return {
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons: [
                                {
                                    text: this.$t('edit'),
                                    class: 'ri-edit-box-line align-middle top-minus-1 mr-3 text-muted',
                                    permission: 'studentnote_update',
                                    callback: (row) => {
                                        this.updateFormShow(row.id);
                                    }
                                },
                                {
                                    text: this.$t('show'),
                                    class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
                                    permission: 'studentnote_show',
                                    callback: (row) => {
                                        this.showFormShow(row.id);
                                    }
                                },
                                {
                                    text: this.$t('delete'),
                                    class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
                                    permission: 'studentnote_delete',
                                    callback: (row) => {
                                        this.deleteForm(row.id)
                                    }
                                }
                            ]
                        },
                        {
                            label: this.$t('note'),
                            field: 'note',
                            sortable: false,
                            thClass: 'text-left',
                            tdClass: 'text-left',
                            hidden: false,
                            html: true,
                            formatFn: (value) => {
                                return '<div>' + this.nl2br(value) + '</div>'
                            }
                        },
                        {
                            label: this.$t('added_by'),
                            field: 'created_by_name',
                            sortable: false,
                            thClass: 'text-center',
                            tdClass: 'text-center',
                            hidden: false,
                            formatFn: (value, row) => {
                                let fullName = '';
                                if (row.created_by_name) fullName += row.created_by_name;
                                if (row.created_by_name && row.created_by_surname) fullName += ' ';
                                if (row.created_by_surname) fullName += row.created_by_surname;

                                return fullName;
                            }
                        },
                        {
                            label: this.$t('added_date'),
                            field: 'created_at',
                            sortable: true,
                            thClass: 'text-center',
                            tdClass: 'text-center',
                            hidden: false,
                            formatFn: (value) => {
                                return value ? moment(value).format("MM/DD/YYYY") : '-';
                            }
                        },
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        sort: 'id',
                        page: 1,
                        limit: 20
                    }
                },
                formId: null,
                formProcess: null
            }
        },
        created() {
            this.filterClear();
        },
        methods: {
            filter() {
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            filterClear() {
                this.datatable.queryParams.filter = {
                    note: null,
                    created_by: null,
                    student_number: this.student.student_number
                }
                this.getRows();
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : ''
                this.datatable.queryParams.sort = sortType + params[0].field
                this.getRows()
            },
            getRows() {
                this.datatable.showTable = true
                this.datatable.isLoading = true

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return StudentNoteService.getAll(config)
                                         .then((response) => {
                                             this.datatable.rows = response.data.data
                                             this.datatable.total = response.data.pagination.total
                                         })
                                         .finally(() => {
                                             this.datatable.isLoading = false;
                                         })
            },

            // Clear
            clearFormData() {
                this.formId = null
                this.formProcess = null
            },

            // Show
            showFormShow(id) {
                this.formId = id
                this.formProcess = 'show'
                this.$refs.formModal.$refs.commonModal.show()
            },

            // Create
            createFormShow() {
                this.formId = null
                this.formProcess = 'create'
                this.$refs.formModal.$refs.commonModal.show()
            },
            createFormSuccess() {
                this.clearFormData();
                this.$refs.formModal.$refs.commonModal.hide()
                this.getRows()
            },

            // Update
            updateFormShow(id) {
                this.formId = id
                this.formProcess = 'update'
                this.$refs.formModal.$refs.commonModal.show()
            },
            updateFormSuccess() {
                this.clearFormData();
                this.$refs.formModal.$refs.commonModal.hide()
                this.getRows()
            },

            // Delete
            deleteForm(id) {
                this.$swal
                    .fire({
                        text: this.$t('are_you_sure_to_delete'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no')
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            StudentNoteService.del(id)
                                              .then(response => {
                                                  this.$toast.success(this.$t('api.' + response.data.message))
                                                  this.getRows()
                                              })
                                              .catch(error => {
                                                  this.$toast.error(this.$t('api.' + error.data.message))
                                              })
                        }
                    })
            }
        }
    }
</script>

